body {
  overflow: hidden;
}

.__react_modal_image__modal_container {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  touch-action: none;
  overflow: hidden;
}

.__react_modal_image__modal_content {
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.__react_modal_image__modal_content img,
.__react_modal_image__modal_content svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  overflow: hidden;
}

.__react_modal_image__medium_img {
  max-width: 98%;
  max-height: 98%;
  padding-bottom: 50px;
}

.__react_modal_image__large_img {
  cursor: move;
  background-color: black /*${imageBackgroundColor}*/
}

.__react_modal_image__icon_menu a {
  display: inline-block;
  font-size: 40px;
  cursor: pointer;
  line-height: 40px;
  box-sizing: border-box;
  border: none;
  padding: 0px 5px 0px 5px;
  margin-left: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0);
}

.__react_modal_image__icon_menu {
  display: inline-block;
  float: right;
}

.__left_float {
  display: inline-block;
  float: left;
}

.__react_modal_image__caption {
  display: inline-block;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
}

.__react_modal_image__header {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
}
