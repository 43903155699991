.main-content-container {
  margin: 1vw;
  margin-top: 0vw;
  padding-top: 1vw;
  overflow-y: scroll;
  height: 94vh;
}

.main-content-section {
  background-color:rgb(238, 236, 235);
  padding: 2rem;
}

.main-content-section:last-child {
  margin-bottom: 2rem;
}
