.cycle {
  margin-bottom: 1vh;
}

.led-box {
  height: 3vh;
  width: 2vw;
  margin: 2px 2vh;
  display: inline-block;
}

.led-red {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #F00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
}

.dull-red {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #A00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
}


.led-green {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #ABFF00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
}

.dull-green {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: rgba(75, 177, 34, 0.993);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
}
